import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import http from "./utils/http";
Vue.prototype.$http = http;

import dom from "./utils/dom";
Vue.prototype.$dom = dom;

Vue.config.productionTip = false;

import { Carousel, CarouselItem, Pagination, Timeline, TimelineItem, Card } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Pagination);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Card);


import VueAwesomeSwiper from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
Vue.use(VueAwesomeSwiper);

import VideoPlayer from "vue-video-player";
require("video.js/dist/video-js.css");
require("vue-video-player/src/custom-theme.css");
Vue.use(VideoPlayer);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
