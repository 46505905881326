<template>
  <article>
    <section class="products">
      <div class="wrapper">

        <div class="subhead">
          <span>航空领域</span>
        </div>
        
        <div class="pure-g">
          <div class="pure-u-1 pure-u-xl-1-1">
            <div class="p1-box">
              <img :src="picture" class="pure-img" />
            </div>
          </div>
          <div class="pure-u-1-2 pure-u-xl-1-3" v-for="(item, index) in list" :key="index">
            <div class="p5-box">
              <div class="products-item">
                <div class="products-from">{{ item.beginPlace }}</div>
                <div style="width: calc(100% / 3);display: flex;justify-content: center;align-items: center;">
                  <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/products/from-to.png" style="width: 30px;height: 30px;">
                </div>
                <div class="products-to" style="">{{ item.endPlace }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </section>
  </article>
</template>

<script>
// import Header from '@/components/header/header'
export default {
  components: {
    // Header
  },
  name: "Product",
  data () {
    return {
      picture: "",
      list: [],
      // headData: {
      //   topBg: 'https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/products/top-bg.png',
      //   menuName: '产品服务',
      //   bgClassName: 'top-bg',
      //   telStyle: ''
      // },
      // productsList: [
      //   {
      //     from: '哈尔滨',
      //     to: '加格达奇'
      //   },
      //   {
      //     from: '哈尔滨',
      //     to: '建三江'
      //   },
      //   {
      //     from: '哈尔滨',
      //     to: '首尔'
      //   },
      //   {
      //     from: '青岛',
      //     to: '首尔'
      //   },
      //   {
      //     from: '佳木斯',
      //     to: '首尔'
      //   },
      //   {
      //     from: '威海',
      //     to: '首尔'
      //   },
      //   {
      //     from: '齐齐哈尔',
      //     to: '首尔'
      //   },
      //   {
      //     from: '烟台',
      //     to: '首尔'
      //   },
      //   {
      //     from: '济州岛',
      //     to: '塞班岛'
      //   },
      //   {
      //     from: '哈尔滨',
      //     to: '济州岛'
      //   },
      //   {
      //     from: '兰州',
      //     to: '济州岛'
      //   },
      //   {
      //     from: '成都',
      //     to: '济州岛'
      //   },
      //   {
      //     from: '福州',
      //     to: '济州岛'
      //   },
      //   {
      //     from: '杭州',
      //     to: '济州岛'
      //   },
      //   {
      //     from: '重庆',
      //     to: '济州岛'
      //   },
      //   {
      //     from: '南昌',
      //     to: '釜山'
      //   },
      //   {
      //     from: '佳木斯',
      //     to: '清州'
      //   },
      //   {
      //     from: '宁波',
      //     to: '清州'
      //   }
      // ]
    }
  },
  created () {
    this.$http.get("/baseInfo/getAviation", {
      pageNo: 1,
      pageSize: 1000,
    }, res => {
      this.list = res.data;
      this.picture = res.picture.picture
    });
  }
}
</script>

<style scoped>
@import "~@/assets/styles/product.css";
</style>
