<template>
  <article>
    <section class="news">
      <div class="wrapper">

        <div class="subhead">
          <span>新闻中心</span>
        </div>

        <div class="pure-g">
          <div class="pure-u-1 pure-u-md-1-3" v-for="(item, index) in list" :key="index" @click="$router.push('/news/' + item.id)"> 
            <div class="p1-box">
              <div class="wrap">
              <img :src="item.thumbnail" class="pure-img" style="height: 264px;">
              <!-- <div class="picture" :style="'background: url(' + item.picture + ') no-repeat center'"></div> -->
              <p class="list-title">{{ item.title }}</p>
              <!-- <p>{{ item.datetime }}</p> -->
              </div>
            </div>
          </div>
        </div>

        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="count"
          :total="total"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          style="text-align: center;">
        </el-pagination>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: "News",
  data() {
    return {
      page: 1,
      count: 6,
      total: 0,
      list: [],
    }
  },
  created() {
    // this.handleCurrentChange(1)
    this.getList();
    // console.log(123);
  },
  methods: {
    getList() {
      this.$http.get("/baseInfo/getNews", {
        pageNo: this.page,
        pageSize: this.count,
      }, res => {
        // console.log(this.list);
        this.list = res.data;
        this.page = res.page.pageNo;
        this.count = res.page.pageSize;
        this.total = res.page.total;
      }, res => {
        console.log(res);
      })
    },
    // onDetail (item) {
    //   this.$router.push(item.url)
    // },
    handleCurrentChange(e) {
      this.page = e;
      this.getList();
      // let arr = []
      // for (let i = this.pageSize * this.page - 6, len = this.newLists.length; i < len; i++) {
      //   arr.push(this.newLists[i])
      // }
      // this.pageNewsLists = arr
    }
  },
  components: {
  }
}
</script>

<style scoped>
@import "~@/assets/styles/news.css";
.news .picture {
  height: 15em;
  background-size: cover;
}
</style>
