<template>
  <article>
    <section class="shzp">
      <div class="wrapper">

        <div class="subhead">
          <span>社会招聘</span>
        </div>

        <div class="p1-box">
          
          <div class="list">
            <div class="item" @click="onDetail(item.id)" v-for="(item, index) in list" :key="index">
              <div class="title1">{{ item.position }}</div>
              <!-- <div class="title2">{{item.bumen}}</div> -->
              <div class="title2">{{ item.publishDate }}</div>
            </div>
          </div>

        </div>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: "Offer",
  data () {
    return {
      list: [],
      lists: []
    }
  },
  methods: {
    getList() {
      this.$http.get("/baseInfo/getSocialRecruitment", {
        pageNo: 1,
        pageSize: 1000,
      }, res => {
        // console.log(this.list);
        this.list = res.data;
        // this.page = res.page.pageNo;
        // this.count = res.page.pageSize;
        // this.total = res.page.total;
      }, res => {
        console.log(res);
      })
    },
    onDetail (e) {
      // this.$router.push('/offer/' + JSON.stringify(this.lists[index]))
      this.$router.push('/offer/' + e)
    }
  },
  created () {
    this.getList();
    // this.$http.get("/jsons/offer.json", {}, res => {
    //   this.lists = res;
    //   console.log(res);
    // });
  },
  components: {
  }
};
</script>

<style scoped>
@import "~@/assets/styles/offer.css";
</style>
