<template>
  <article>
  
    <!-- 公司简介 begin -->
    <section id="top-company" class="gongsijianjie">
      <div class="wrapper">
        <div class="p1-box">
          <div class="pure-g">
              <div class="pure-u-1 pure-u-xl-1-3">
                <p class="title" style="text-align: center;">公司简介</p>
              </div>
              <div class="pure-u-1 pure-u-xl-2-3">
                <p>{{ company.detail }}</p>
              </div>
          </div>
          
        </div>
      </div>
    </section>
    <!-- 公司简介 end -->

    <!-- 员工风采 begin -->
    <section id="top-staff" class="employee">

      <div class="subhead">
        <span>员工风采</span>
      </div>

      <div class="p1-box">


        <!-- <div ref="mySwiper" v-swiper:mySwiper="swiperOption" class="swiper-container ygfc-swiper" @click="$router.push('/profile/staff')">
          <div class="swiper-wrapper">
            <div class="swiper-slide" :data-history="banner.imgSrc" :key="banner.imgSrc" v-for="banner in banners" @click="$router.push('/profile/staff')">
              <img :src="banner.imgSrc" style="width: 100%;" @click="$router.push('/profile/staff')">
              <p @click="$router.push('/profile/staff')" style="height: 50px;line-height: 50px;background: rgba(0, 0, 0, 0.4);position: relative;margin-top: -51px;color: #ffffff;text-align: center;">{{banner.title}}</p>
            </div>
          </div>
        </div> -->

        <div ref="mySwiper" v-swiper:mySwiper="swiperOption" class="swiper-container ygfc-swiper" @click="$router.push('/profile/staff')">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in staffList" :key="index">
              <img :src="item.picture" style="width: 100%;">
              <p style="height: 50px;line-height: 50px;background: rgba(0, 0, 0, 0.4);position: relative;margin-top: -50px;color: #ffffff;text-align: center;">{{ item.title }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 员工风采 end -->

    <!-- 办公环境 begin -->
    <section id="top-office" class="bghj">
      <div class="wrapper">
        <div class="subhead">
          <span>办公环境</span>
        </div>

        <div class="p1-box">
          <!-- <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/arrow-left.png" @click="moveLeftSlide" class="leftSlideImg">
          <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/arrow-right.png"  @click="moveRightSlide" class="rightSlideImg"> -->

          <swiper ref="bangongSwiper" class="swiper" :options="swiperOption1">
            <swiper-slide v-for="(item, index) in officeList" :key="index">
              <img :src="item.picture" class="pure-img">
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
      </div>
    </section>
    <!-- 办公环境 end -->

    <!-- 企业文化 begin -->
    <section id="top-pro" class="qywh">
      <div class="wrapper">
        <div class="p1-box">
          <div class="qiyewenhua-bg">
            <div style="text-align: center;">
              <p class="qiyetitle" style="margin-top: 10px;">企业价值观</p>
              <p class="qiyetitle-en">CORPORATE VISION</p>
              <p class="qiyefengexian">
                <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/zhongjianxian.png">
              </p>
              <p class="qiyejieshao">高效、细致、讲责任、有担当、注重执行力</p>
            </div>
            <div style="text-align: center;">
              <p class="qiyetitle">企业愿景</p>
              <p class="qiyetitle-en">BUSINESS PHILOSOPHY</p>
              <p class="qiyefengexian">
                <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/zhongjianxian.png">
              </p>
              <p class="qiyejieshao">高效运营、稳健长青的航空运输企业</p>
            </div>
            <div style="text-align: center;">
              <p class="qiyetitle">企业使命</p>
              <p class="qiyetitle-en">CORPORATE VALUES</p>
              <p class="qiyefengexian">
                <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/zhongjianxian.png">
              </p>
              <p class="qiyejieshao">做您双翼，飞遍世界</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 企业文化 end -->
    <!-- 组织架构 -->

    <section id="profile-one">
      <div class="wrapper">
        <div class="subhead">
          <span>组织架构</span>
        </div>
        <div class="p1-box" style="display: block; justify-content: center;">
          <!-- <tree-chart :json="orgList"></tree-chart> -->
          <img :src="orgImg" class="pure-img" style="object-fit: fill;" />
        </div>
      </div>
    </section>
    <!-- <section id="profile-one" class="zzjg">
      <div class="wrapper">

        <div class="subhead">
          <span>组织架构</span>
        </div>

        <div class="zzjg-1">
          <p>股东大会</p>
          <p class="sx"></p>
        </div>
        <div class="zzjg-xian-1">
          <span></span>
        </div>
        <div class="zzjg-2">
          <div class="zzjg-xian-2"></div>
        </div>
        <div class="zzjg-3">
          <div class="zzjg-3-1"><p>监事会</p></div>
          <div class="zzjg-3-2">
            <p style="margin-left: calc(50% * 10% + 90px)" @mouseenter="dszShow = true" @mouseleave="dszShow = false">董事长(董事会)</p>
            <div class="zzjg-dsz" v-show="dszShow">
              <div>
                <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/tx-1.png" class="dsz-touxian">
              </div>
              <div class="dsz-info">
                <p>金秋野</p>
                <p>黑龙江天鹅航空有限公司</p>
                <p>董事长</p>
              </div>
            </div>
          </div>
        </div>
        <div class="zzjg-xian-3"></div>
        <div class="zzjg-4"></div>
        <div class="zzjg-xian-4">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div class="zzjg-5">
          <div class="zzjg-5-1">
            <p>董事会秘书</p>
            <p></p>
          </div>
          <div class="zzjg-5-2">
            <p @mouseenter="dszzlShow = true" @mouseleave="dszzlShow = false">董事长助理</p>
            <div class="zzjg-dszzl" v-show="dszzlShow">
              <div>
                <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/tx-2.png" class="dszzl-touxian">
              </div>
              <div class="dszzl-info">
                <p>李媛</p>
                <p>黑龙江天鹅航空有限公司</p>
                <p>董事长助理</p>
              </div>
            </div>
          </div>
          <div class="zzjg-5-3">
            <p>总裁</p>
            <p></p>
          </div>
          <div class="zzjg-5-4">
            <div class="wm-lr">风控委员会</div>
          </div>
          <div class="zzjg-5-5">
            <div class="wm-lr">薪酬与考核委员会</div>
          </div>
          <div class="zzjg-5-6">
            <div class="wm-lr">发展战略委员会</div>
          </div>
          <div class="zzjg-5-7">
            <div class="wm-lr">审计委员会</div>
          </div>
        </div>
        <div class="zzjg-6">
          <div class="zzjg-xian-6"></div>
          <div class="zzjg-xian-6-1">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="zzjg-7">
          <div class="div1">总裁助理</div>
          <div class="div2" @mouseenter="zjlShow = true" @mouseleave="zjlShow = false">总经理</div>
          <div class="div3">总经理</div>
          <div class="div4">总经理</div>
          <div class="zzjg-zjl" v-show="zjlShow">
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/tx-8.png" class="zjl-touxian">
            <div class="zjl-info">
              <p>金中华</p>
              <p>黑龙江天鹅航空有限公司</p>
              <p>副总经理</p>
            </div>
          </div>
        </div>
        <div class="zzjg-xian-5">
          <div></div>
          <div></div>
        </div>
        <div class="zzjg-xian-7">
          <div class="zzjg-xian-7-1">
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div class="zzjg-xian-7-2">
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div class="zzjg-8">
          <div class="zzjg-8-1">
            <div class="wm-lr">证券事务中心</div>
          </div>
          <div class="zzjg-8-2">
            <div class="wm-lr">市场开发中心</div>
          </div>
          <div class="zzjg-8-3" @mouseenter="scyxzxShow = true" @mouseleave="scyxzxShow = false">
            <div class="wm-lr">市场营销中心</div>
          </div>
          <div class="zzjg-scyxzx" v-show="scyxzxShow">
            <div>
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/tx-7.png" class="scyxzx-touxian">
            </div>
            <div class="scyxzx-info">
              <p>叶泳男</p>
              <p>黑龙江天鹅航空有限公司</p>
              <p>市场营销中心总监</p>
            </div>
          </div>
          <div class="zzjg-8-3" @mouseenter="syzxShow = true" @mouseleave="syzxShow = false">
            <div class="wm-lr">国内收益管理中心</div>
          </div>
          <div class="zzjg-8-3" @mouseenter="syzxShow = true" @mouseleave="syzxShow = false">
            <div class="wm-lr">国际收益管理中心</div>
          </div>
          <div class="zzjg-syzx" v-show="syzxShow">
            <div>
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/tx-10.png" class="syzx-touxian">
            </div>
            <div class="syzx-info">
              <p>金善花</p>
              <p>黑龙江天鹅航空有限公司</p>
              <p>收益管理中心部门经理</p>
            </div>
          </div>
          <div class="zzjg-8-4" @mouseenter="wlzxShow = true" @mouseleave="wlzxShow = false">
            <div class="wm-lr">网络技术中心</div>
          </div>
          <div class="zzjg-wlzx" v-show="wlzxShow">
            <div>
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/tx-4.png" class="wlzx-touxian">
            </div>
            <div class="wlzx-info">
              <p>魏成刚</p>
              <p>黑龙江天鹅航空有限公司</p>
              <p>软件工程师</p>
            </div>
          </div>
          <div class="zzjg-8-5" @mouseenter="cwzxShow = true" @mouseleave="cwzxShow = false">
            <div class="wm-lr">财务中心</div>
          </div>
          <div class="zzjg-cwzx" v-show="cwzxShow">
            <div>
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/tx-6.png" class="cwzx-touxian">
            </div>
            <div class="cwzx-info">
              <p>祖杨铭</p>
              <p>黑龙江天鹅航空有限公司</p>
              <p>财务中心总监</p>
            </div>
          </div>
          <div class="zzjg-8-6" @mouseenter="rlzxShow = true" @mouseleave="rlzxShow = false">
            <div class="wm-lr">人力资源中心</div>
          </div>
          <div class="zzjg-rlzx" v-show="rlzxShow">
            <div>
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/tx-11.png" class="rlzx-touxian">
            </div>
            <div class="rlzx-info">
              <p>范娜娜</p>
              <p>黑龙江天鹅航空有限公司</p>
              <p>人力资源中心部门经理</p>
            </div>
          </div>
          <div class="zzjg-8-7" @mouseenter="xzzxShow = true" @mouseleave="xzzxShow = false">
            <div class="wm-lr">行政中心</div>
          </div>
          <div class="zzjg-xzzx" v-show="xzzxShow">
            <div>
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/tx-5.png" class="xzzx-touxian">
            </div>
            <div class="xzzx-info">
              <p>宋欢欢</p>
              <p>黑龙江天鹅航空有限公司</p>
              <p>行政中心部门经理</p>
            </div>
          </div>
          <div class="zzjg-8-7">
            <div class="wm-lr">企划中心</div>
          </div>
          <div class="zzjg-8-8">
            <div class="wm-lr">法务中心</div>
          </div>
          <div class="zzjg-8-9" @mouseenter="jwzgsShow = true" @mouseleave="jwzgsShow = false">
            <div class="wm-lr">境外子公司</div>
          </div>
          <div class="zzjg-jwzgs" v-show="jwzgsShow">
            <div>
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/tx-3.png" class="jwzgs-touxian">
            </div>
            <div class="jwzgs-info">
              <p>李峰</p>
              <p>黑龙江天鹅航空有限公司</p>
              <p>境外子公司部长</p>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <!-- 组织架构 end -->
    <!-- 联系我们 -->
    <section id="top-contact" class="lxwm">
      <div class="wrapper">

        <div class="subhead fill">
          <span>联系我们</span>
        </div>

        <div class="pure-g">
          <div class="pure-u-1 pure-u-xl-1-2">
            <div class="p1-box">
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/map.png" class="pure-img">
            </div>
          </div>
          <div class="pure-u-1 pure-u-xl-1-2 m-addr-phone">
            <div class="p1-box">
              <div class="lxwm-info">
                <div class="addr">
                  <div class="">
                    <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/addr.png" style="width: 35px;">
                  </div>
                  <p style="height: 35px;margin:  0 0 0 10px;">哈尔滨高新技术产业开发区科技创新城创新创业广场20号楼（秀月街178号）A307－8室</p>
                </div>
                <div class="email-tel">
                  <div class="">
                    <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/profile/tel.png" style="width: 35px;">
                  </div>
                  <p style="height: 35px;margin: 0 0 0 10px;">0451－55559999</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- 联系我们 end -->
  </article>
</template>

<script>
// import Header from '@/components/header/header'
// import TreeChart from "vue-tree-chart";
// import TreeChart from "@/components/TreeChart.vue";


export default {
  name: "Profile",
  data () {
    return {
      isMobile: document.body.clientWidth < 1280 ? true : false,
      orgImg: "",
      officeList: [],
      staffList: [],
      orgList: [],
      company: {},
      timeIndex: 3,
      timeCurIndex: 1,
      curIndex: 1,
      swiperOption: {
        autoplay: {
          delay: 2500,
          disableOnInteraction: false
        },
        loop: true,
        breakpoints: { 
          768: {  //当屏幕宽度大于等于768 
            slidesPerView: 3,
            spaceBetween: 20
          },
          1280: {  //当屏幕宽度大于等于1280
            slidesPerView: 3,
            spaceBetween: 30
          },
          1440: {  //当屏幕宽度大于等于1440
            slidesPerView: 4,
            spaceBetween: 30
          },
          1920: {  //当屏幕宽度大于等于1920
            slidesPerView: 5,
            spaceBetween: 10
          }
        }
      },
      curLunbo: 4,
      swiperOption1: {
        slidesPerView: document.body.clientWidth < 1280 ? 2 : 3,
        spaceBetween: 10,
        slidesPerGroup: 1,
        loop: true,
        loopFillGroupWithBlank: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      dszShow: false,
      dszzlShow: false,
      jwzgsShow: false,
      wlzxShow: false,
      xzzxShow: false,
      cwzxShow: false,
      scyxzxShow: false,
      zjlShow: false,
      syzxShow: false,
      rlzxShow: false
    }
  },
  methods: {
    moveLeftSlide () {
      this.bangongSwiper.slidePrev()
    },
    moveRightSlide () {
      this.bangongSwiper.slideNext()
    },
  },
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper
    },
    bangongSwiper () {
      return this.$refs.bangongSwiper.$swiper
    }
  },
  watch: {
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.isMobile = document.body.clientWidth < 1280 ? true : false;
    });
  },
  created () {

    this.$http.get("/baseInfo/getDepartment", {}, res => {
      this.orgImg = res.data[0].picture
    });
    // console.log(this.$route);

    this.$http.get("/baseInfo/getCompanyDetail", {}, res => {
      this.company = res.data[0];
    });
    this.$http.get("/baseInfo/getOfficeService", {
      pageNo: 1,
      pageSize: 1000,
    }, res => {
      this.officeList = res.data;
    });
    this.$http.get("/baseInfo/getStaffStyle", {
      pageNo: 1,
      pageSize: 1000,
    }, res => {
      this.staffList = res.data;
    });

    // this.$http.get("/baseInfo/getDepartmentTree", {
    //   pageNo: 1,
    //   pageSize: 1000,
    // }, res => {
    //   this.orgList = res.data[0];
    // });
    // this.$http.get("/jsons/organization.json", {}, res => {
    //   console.log(res);
    // });

    setInterval(() => {
      this.curLunbo += 1
      if (this.curLunbo >= this.staffList.length) {
        this.curLunbo = 1
      }
      this.swiper.slideTo(this.curLunbo, 1000, false)
    }, 3000)
  },
  components: {
    // Header
    // TreeChart
    // TreeChart
  }
}
</script>

<style scoped>
@import "~@/assets/styles/profile.css";
</style>
