<template>
  <article id="main">
    <!-- <header></header> -->
    <div class="wrapper">
      <div class="inner">
        <section id="cd-timeline" class="cd-container">
          <div v-for="(item, index) in companyHistoryList" :key="index" class="cd-timeline-block">
            <div class="cd-timeline-img">
              <!-- <img src="images/cd-icon-picture.svg" alt="Picture"> -->
            </div>

            <div class="cd-timeline-content">
              <!-- <h2>{{ item.timestamp }}年</h2> -->
              <!-- {{item}} -->
              <!-- <div class="row gtr-0" v-if="item.imgs">
                <div class="col-12" v-for="(img, key) in item.imgs" :key="key">
                  <a :href="img.url" target="_blank"><img :src="img.url" class="img"></a>
                </div>
              </div> -->
              <div>
                <span class="cd-date">{{ item.timestamp }}年</span>
              </div>
              <div class="row gtr-0" v-if="item.info">
                <div class="col-12" v-for="(img, key) in item.info" :key="key">
                  <p>{{img.title}}</p>
                  <p>{{img.content}}</p>
                </div>
              </div>
              
              <div class="row gtr-0">
                <div class="col-12">
                  <img :src="'https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/history/'+item.timestamp+'.png'" class="img">
                </div>
              </div>
              
              <!-- <a href="#" class="cd-read-more">阅读更多</a> -->
            </div>
          </div>

        </section>
      </div>
    </div>
  </article>

</template>

<script>
export default {
  data() {
    return {
      fourTimeLineList: [],
      timeLineList: [],
      companyHistoryList: [],
    };
  },
  created() {
    console.log(this.$router);
    this.$http.get("/baseInfo/getDevelopmentHistory", {
      pageNo: 1,
      pageSize: 1000,
    }, res => {
      // console.log(res);
      let list = [];
      // console.log("@", list);
      res.data.forEach((item, index) => {
        // list[item.year].info.push(item);
        // console.log(item.year);
        list[item.year] = [];
      })
      res.data.forEach((item, index) => {
        // list[item.year].info.push(item);
        // console.log(item.year);
        list[item.year].unshift(item)
      })
      let companyHistoryList = [];
      list.forEach((item, index) => {
        console.log(item, index);
        companyHistoryList.push({
          timestamp: index,
          info: item
        })
      })
      console.log(companyHistoryList)
      // companyHistoryList = companyHistoryList.reverse();

      companyHistoryList.reverse().forEach((item, index) => {
        // if (index < 4) {
        //   this.fourTimeLineList.push(item);
        // } else {
        //   this.timeLineList.push(item);
        // }
        this.companyHistoryList.push(item)
      })



      // this.companyHistoryList = companyHistoryList;
        // console.log("@@", companyHistoryList);
      // this.companyHistoryList = res.data;
    });
  }
}
</script>

<style scoped>
.img {
  max-width: 100%;
  display: flex;
}
.cd-container {
  /* this class is used to give a max-width to the element it is applied to, and center it horizontally when it reaches that max-width */
  width: 90%;
  max-width: 1170px;
  margin: 0 auto;
}
.cd-container::after {
  /* clearfix */
  content: '';
  display: table;
  clear: both;
}


#cd-timeline {
  position: relative;
  padding: 2em 0;
  margin-top: 2em;
  margin-bottom: 2em;
}
#cd-timeline::before {
  /* this is the vertical line */
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  /*background: #d7e4ed;*/
  /*background: #000;*/
  background: #0b509d;
}
@media only screen and (min-width: 1170px) {
  #cd-timeline {
    margin-top: 3em;
    margin-bottom: 3em;
  }
  #cd-timeline::before {
    left: 50%;
    margin-left: -2px;
  }
}

.cd-timeline-block {
  position: relative;
  margin: 2em 0;
}
.cd-timeline-block:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-block:first-child {
  margin-top: 0;
}
.cd-timeline-block:last-child {
  margin-bottom: 0;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-block {
    margin: 4em 0;
  }
  .cd-timeline-block:first-child {
    margin-top: 0;
  }
  .cd-timeline-block:last-child {
    margin-bottom: 0;
  }
}

.cd-timeline-img {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /*box-shadow: 0 0 0 4px #ffffff, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);*/
  /*background: #000;*/
  background: #0b509d;
}
.cd-timeline-img img {
  display: block;
  width: 24px;
  height: 24px;
  position: relative;
  left: 50%;
  top: 50%;
  margin-left: -12px;
  margin-top: -12px;
}
.cd-timeline-img.cd-picture {
  background: #75ce66;
}
.cd-timeline-img.cd-movie {
  background: #c03b44;
}
.cd-timeline-img.cd-location {
  background: #f0ca45;
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-img {
    width: 30px;
    height: 30px;
    left: 50%;
    margin-left: -15px;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .cssanimations .cd-timeline-img.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-img.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-1 0.6s;
    -moz-animation: cd-bounce-1 0.6s;
    animation: cd-bounce-1 0.6s;
  }
}

@-webkit-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
  }
}
@-moz-keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -moz-transform: scale(1.2);
  }
  100% {
    -moz-transform: scale(1);
  }
}
@keyframes cd-bounce-1 {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
    -moz-transform: scale(0.5);
    -ms-transform: scale(0.5);
    -o-transform: scale(0.5);
    transform: scale(0.5);
  }
  60% {
    opacity: 1;
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
    transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
.cd-timeline-content {
  position: relative;
  margin-left: 60px;
  /*background: #ffffff;*/
  /*background: rgba(0, 0, 0, .2);*/

  border-radius: 0.25em;
  /*padding: 1em;*/
  /*box-shadow: 0 3px 0 #d7e4ed;*/
}
.cd-timeline-content:after {
  content: "";
  display: table;
  clear: both;
}
.cd-timeline-content h2 {
  /*color: #303e49;*/
  color: #ffd100;
}
.cd-timeline-content p,
.cd-timeline-content .cd-read-more {
  font-size: 13px;
  /*font-size: 0.8125rem;*/
}
.cd-timeline-content .cd-date {
  font-size: 30px;
  margin: 0;
  padding: 0;
}
.cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
  display: inline-block;
  color: #0b509d;
  /*font-size: 26px;*/
}
.cd-timeline-content p {
  margin: 1em 0;
  line-height: 1.6;
}
.cd-timeline-content .cd-read-more {
  float: right;
  padding: .8em 1em;
  background: #acb7c0;
  color: #ffffff;
  border-radius: 0.25em;
}
.no-touch .cd-timeline-content .cd-read-more:hover {
  background-color: #bac4cb;
}
.cd-timeline-content .cd-date {
  /*float: left;*/
  /*padding: .8em 0;*/
  opacity: .7;
}
.cd-timeline-content::before {
  content: '';
  position: absolute;
  top: 16px;
  right: 100%;
  height: 0;
  width: 0;
  border: 7px solid transparent;
  /*border-right: 7px solid #ffffff;*/
  /*border-right: 7px solid rgba(0, 0, 0, .2);*/
}
@media only screen and (min-width: 768px) {
  .cd-timeline-content h2 {
    font-size: 20px;
    font-size: 1.25rem;
  }
  .cd-timeline-content p {
    font-size: 9px;

    font-size: 16px;
    /*font-size: .8em;*/
  }
  .cd-timeline-content .cd-read-more, .cd-timeline-content .cd-date {
    /*font-size: 14px;*/
    /*font-size: 0.875rem;*/
    font-size: 26px;
  }
}
@media only screen and (min-width: 1170px) {
  .cd-timeline-content {
    margin-left: 0;
    padding: 1.6em;
    width: 45%;
  }
  .cd-timeline-content::before {
    top: 24px;
    left: 100%;
    border-color: transparent;
    /*border-left-color: #ffffff;*/
    /*border-left-color: rgba(0, 0, 0, .2);*/
  }
  .cd-timeline-content .cd-read-more {
    float: left;
  }
  .cd-timeline-content .cd-date {
    position: absolute;
    width: 100%;
    left: 122%;
    left: 110%;
    height: 30px;
    line-height: 30px;
    padding: 0;
    /*top: 6px;*/
    top: 0;
    font-size: 26px;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content::before {
    top: 24px;
    left: auto;
    right: 100%;
    border-color: transparent;
    /*border-right-color: #ffffff;*/
    /*border-right-color: rgba(0, 0, 0, .2);*/
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-read-more {
    float: right;
  }
  .cd-timeline-block:nth-child(even) .cd-timeline-content .cd-date {
    left: auto;
    /*right: 122%;*/
    right: 110%;
    text-align: right;
  }
  .cssanimations .cd-timeline-content.is-hidden {
    visibility: hidden;
  }
  .cssanimations .cd-timeline-content.bounce-in {
    visibility: visible;
    -webkit-animation: cd-bounce-2 0.6s;
    -moz-animation: cd-bounce-2 0.6s;
    animation: cd-bounce-2 0.6s;
  }
}

@media only screen and (min-width: 1170px) {
  /* inverse bounce effect on even content blocks */
  .cssanimations .cd-timeline-block:nth-child(even) .cd-timeline-content.bounce-in {
    -webkit-animation: cd-bounce-2-inverse 0.6s;
    -moz-animation: cd-bounce-2-inverse 0.6s;
    animation: cd-bounce-2-inverse 0.6s;
  }
}
@-webkit-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -moz-transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2 {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100px);
    -moz-transform: translateX(-100px);
    -ms-transform: translateX(-100px);
    -o-transform: translateX(-100px);
    transform: translateX(-100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    -moz-transform: translateX(20px);
    -ms-transform: translateX(20px);
    -o-transform: translateX(20px);
    transform: translateX(20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
@-webkit-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
  }
}
@-moz-keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -moz-transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -moz-transform: translateX(-20px);
  }
  100% {
    -moz-transform: translateX(0);
  }
}
@keyframes cd-bounce-2-inverse {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100px);
    -moz-transform: translateX(100px);
    -ms-transform: translateX(100px);
    -o-transform: translateX(100px);
    transform: translateX(100px);
  }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    -moz-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    -o-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}
</style>
