<template>
  <article>
    <!-- 公司发展历程 begin -->
    <section id="home-one">
      <div class="wrapper">
        <div class="subhead">
          <span>发展历程</span>
        </div>
        <!-- <div class="timeline">
          <div class="line"></div>
          <div class="wrap">
            <div class="my_timeline_prev" @click="moveRight">
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/left.png" />
            </div>
            <div class="my_timeline_item" v-for="(item, index) in fourTimeLineList" :key="index">
              <div class="my_timeline_item_content" style="color: #e73c85;" v-if="index % 4 == 0">{{item.timestamp}}</div>
              <div class="my_timeline_item_content" style="color: #52676f;" v-else-if="index % 4 == 1">{{item.timestamp}}</div>
              <div class="my_timeline_item_content" style="color: #e88511;" v-else-if="index % 4 == 2">{{item.timestamp}}</div>
              <div class="my_timeline_item_content" style="color: #5caab8;" v-else-if="index % 4 == 3">{{item.timestamp}}</div>
              <div class="my_timeline_node s1-bg" v-if="index % 4 == 0"></div>
              <div class="my_timeline_node s2-bg" v-if="index % 4 == 1"></div>
              <div class="my_timeline_node s3-bg" v-if="index % 4 == 2"></div>
              <div class="my_timeline_node s4-bg" v-if="index % 4 == 3"></div>
              <div class="my_timeline_item_y_line"></div>
              <div class="c1-bg" v-if="index % 4 == 0" @click="$router.push('/profile/history')">
                <div class="w" v-for="(info, infoIndex) in item.info" :key="infoIndex">
                  <span>{{info.title}}</span>
                  <p>{{info.content}}</p>
                </div>
              </div>
              <div class="c2-bg" v-if="index % 4 == 1" @click="$router.push('/profile/history')">
                <div class="w" v-for="(info, infoIndex) in item.info" :key="infoIndex">
                  <span>{{info.title}}</span>
                  <p>{{info.content}}</p>
                </div>
              </div>
              <div class="c3-bg" v-if="index % 4 == 2" @click="$router.push('/profile/history')">
                <div class="w" v-for="(info, infoIndex) in item.info" :key="infoIndex">
                  <span>{{info.title}}</span>
                  <p>{{info.content}}</p>
                </div>
              </div>
              <div class="c4-bg" v-if="index % 4 == 3" @click="$router.push('/profile/history')">
                <div class="w" v-for="(info, infoIndex) in item.info" :key="infoIndex">
                  <span>{{info.title}}</span>
                  <p>{{info.content}}</p>
                </div>
              </div>
            </div>
            <div class="my_timeline_next" @click="moveLeft">
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/right.png" />
            </div>
          </div>
        </div> -->
        <div class="timeline">
          <div class="line"></div>
          <div class="wrap">
            <div class="my_timeline_prev" @click="tlLeft">
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/left.png" />
            </div>
            <div class="my_timeline_item" v-for="(item, index) in fourTimeLineList" :key="index">
              <div class="my_timeline_item_content" :class="'tl-t-' + (index + 1)">{{ item.timestamp }}</div>
              <div class="my_timeline_node" :class="'s' + (index + 1) + '-bg'"></div>
              <div class="my_timeline_item_y_line"></div>
              <div :class="'c' + (index + 1) + '-bg'" @click="$router.push('/profile/history')">
                <div class="w" v-for="(info, infoIndex) in item.info" :key="infoIndex">
                  <span>{{ info.title }}</span>
                  <p>{{ info.content }}</p>
                </div>
              </div>
            </div>
            <div class="my_timeline_next" @click="tlRight">
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/right.png" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 公司发展历程 end -->

    <!-- 公司简介 begin -->
    <section id="home-two">
      <div class="wrapper">
        <div class="subhead fill">
          <span>公司简介</span>
        </div>


        <div class="p1-box">
          <div class="intro">
            <!-- <p>黑龙江天鹅航空集团成立于2014年，总部位于美丽的冰城哈尔滨，是集航空及旅游业务为一体的综合性企业。</p>
            <p>集团下设哈尔滨必爱谊国际旅行社有限公司、韩国天鹅航空株式会社两家子公司。</p>
            <p>凭借强大的资源优势、稳定的专业人才队伍、持续上升的经营业绩，在省内同行业中位居前列。</p> -->
            <p style="white-space: pre-wrap;">{{ company.simpleDetail }}</p>
          </div>


          <div class="banner">
            <div class="pure-g">
              <div class="pure-u-1-3 pure-u-xl-1-3">
                <div class="item">
                  <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/qyjzg.png">
                  <span>企业价值观</span>
                  <p>高效、细致、讲责任、有担当、注重执行力</p>
                </div>
              </div>
              <div class="pure-u-1-3 pure-u-xl-1-3">

                <div class="item">
                  <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/qyyj.png">
                  <span>企业愿景</span>
                  <p>高效运营、稳健长青的航空运输企业</p>
                </div>
              </div>
              <div class="pure-u-1-3 pure-u-xl-1-3">

                <div class="item">
                  <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/jyln.png">
                  <span>企业使命</span>
                  <p>做您双翼，飞遍世界</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
    <!-- 公司简介 end -->

    <div id="home-one-mobile" @click="$router.push('/profile/history')">
      <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/mobile/timeline-bg.png">
    </div>

    <!-- 产品服务 -->
    <section id="home-three">
      <div class="wrapper">

        <div class="subhead">
          <span>产品服务</span>
        </div>

        <div class="banner">
          <div class="pure-g">
            <div class="pure-u-1-3 pure-u-xl-1-3">
              <div class="item">
                <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/1.png">
                <span>航空领域</span>
                <p>Aviation sector</p>
              </div>
            </div>
            <div class="pure-u-1-3 pure-u-xl-1-3">
              <div class="item">
                <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/3.png">
                <span>酒店领域</span>
                <p>Hotel field</p>
              </div>
            </div>
            <div class="pure-u-1-3 pure-u-xl-1-3">
              <div class="item">
                <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/2.png" >
                <span>电子商务</span>
                <p>E-commerce</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- 产品服务 end -->

    <!-- 新闻中心 -->
    <section id="home-four">
      <div class="wrapper">

        <div class="subhead fill">
          <span>新闻中心</span>
        </div>

        <div class="p1-box">
          <div class="carousel">
            <el-carousel :height="carouselType == 'card' ? '350px' : '280px'" :interval="40000" indicator-position="none"
              :type="carouselType">
              <el-carousel-item v-for="(item, index) in xinwenImgList" :key="index">
                <!-- <img :src="item.picture" class="pure-img" @click="$router.push('/news/' + item.id)"> -->
                <div class="picture"
                  :style="'height: ' + (carouselType == 'card' ? '350px' : '280px') + ';background: url(' + item.thumbnail + ') no-repeat center'">
                </div>
                <div class="title">{{ item.title }}</div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="btn-box">
          <button class="pure-button" @click="$router.push('/news')">查看更多</button>
        </div>
      </div>
    </section>
    <!-- 新闻中心 end -->
    <!-- 合作伙伴 begin -->
    <section id="home-five">
      <div class="wrapper">
        <div class="subhead">
          <span>合作伙伴0</span>
        </div>

        <div class="p1-box">
          <img v-if="isMobile"
            src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/mobile/link-bg.png" />
          <img v-else src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/hzhb.png" />
        </div>
      </div>
    </section>

    <!-- 合作伙伴 end -->

    <!-- 首页动画 begin -->
    <div class="startup-animation" @touchmove.prevent v-if="startupAnimation">
      <div class="gif" v-if="isMobile"></div>
      <video-player :playsinline="true" :options="playerOptions" v-else></video-player>
      <audio ref="bgm" autoplay loop :src="startupBGM" hidden></audio>
      <div class="close">
        <i class="icon" @click="startupAnimation = false"></i>
      </div>
    </div>
    <!-- 首页动画 end -->
  </article>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      tlIndex: 9,
      company: {},
      isMobile: document.body.clientWidth < 1280 ? true : false,
      startupBGM: "https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/startup.mp3",
      startupAnimation: true,
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0],
        autoplay: true,
        muted: true,
        loop: true,
        preload: "auto",
        language: "zh-CN",
        aspectRatio: "16:9",
        fluid: true,
        sources: [
          {
            type: "video/mp4",
            src: "https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/startup.mp4"
          }
        ],
        poster: "",
        notSupportedMessage: "此视频暂无法播放，请稍后再试",
        controlBar: false
      },

      carouselType: "card",
      startShow: false,
      enterShow: true,
      timeIndex: 3,
      xinwenImgList: [
        // {
        //   title: '黑龙江天鹅航空有限公司第二次IPO会议',
        //   imgSrc: require('https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/xinwen-1.png'),
        //   url: '/news/9'
        // },
        // {
        //   title: '黑龙江天鹅航空有限公司举办 “成都航空开通哈尔滨”',
        //   imgSrc: require('https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/xinwen-2.png'),
        //   url: '/news/10'
        // },
        // {
        //   title: '黑龙江天鹅航空有限公司端午节组织全员包粽子活动',
        //   imgSrc: require('https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/xinwen-3.png'),
        //   url: '/news/11'
        // }
      ],
      fourTimeLineList: [],
      timeLineList: [],
      companyHistoryList: [],
    }
  },
  created() {
    let timestamp = Date.parse(new Date());
    let startup = Number(localStorage.getItem("startup"));
    if (startup) {
      // console.log(startup, timestamp, timestamp > (startup + 5000), (startup + 5000));
      if (timestamp > (startup + 3600000)) {
        this.startupAnimation = true;
        localStorage.setItem("startup", timestamp);
      } else {
        this.startupAnimation = false;
      }
    } else {
      localStorage.setItem("startup", timestamp);
    }
    // if (startup > timestamp)
    // localStorage.setItem("startup", Date.parse(new Date()));

    this.$http.get("/baseInfo/getCompanyDetail", {}, res => {
      this.company = res.data[0];
      // console.log(this.company);
    });

    document.body.style.overflow = "hidden";
    // this.video();
    // console.log(document.body.clientWidth);
    this.carouselType = document.body.clientWidth < 1280 ? "horizontal" : "card";


    this.$http.get("/baseInfo/getNews", {
      pageNo: 1,
      pageSize: 3,
    }, res => {
      console.log(res);
      this.xinwenImgList = res.data;
    });

    this.$http.get("/baseInfo/getDevelopmentHistory", {
      pageNo: 1,
      pageSize: 1000,
    }, res => {
      // console.log(res);
      let list = [];
      // console.log("@", list);
      res.data.forEach((item, index) => {
        // list[item.year].info.push(item);
        // console.log(item.year);
        list[item.year] = [];
      })
      res.data.forEach((item, index) => {
        // list[item.year].info.push(item);
        // console.log(item.year);
        list[item.year].push(item)
      })
      let companyHistoryList = [];
      list.forEach((item, index) => {
        // console.log(item, index);
        companyHistoryList.push({
          timestamp: index,
          info: item
        })
      })

      // companyHistoryList.sort((a, b) => {
      //   return b.timestamp - a.timestamp
      // })
      // console.log(companyHistoryList)
      // companyHistoryList = companyHistoryList.reverse();

      companyHistoryList.reverse().forEach((item, index) => {
        if (index < 4) {
          this.fourTimeLineList.unshift(item);

        }
        console.log(item)
        //  else {
        //   this.timeLineList.push(item);
        // }
        this.companyHistoryList.unshift(item)
      })

      // this.companyHistoryList = companyHistoryList;
      // console.log("@@", companyHistoryList);
      // this.companyHistoryList = res.data;
    });
    // 开始动画
    // this.onStart();
  },
  mounted() {
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf('micromessenger') > -1) {
      document.addEventListener("WeixinJSBridgeReady", () => {
        this.$refs.bgm.play();
      }, false)
    }
    window.addEventListener("resize", () => {
      this.isMobile = document.body.clientWidth < 1280 ? true : false;
    });
  },
  methods: {
    onEnter() {
      this.startShow = false
      this.enterShow = false
    },
    onPlayerPlay() {
      let myPlayer = this.$refs.videoPlayer.player
      myPlayer.play()
    },
    onPlayerEnded() {
      this.startShow = false
      this.enterShow = false
    },
    onStart() {
      let vm = this
      vm.startShow = true
      this.onPlayerPlay(this.$refs.videoPlayer.player)
    },
    moveLeft() {
      if (this.timeIndex == this.timeLineList.length - 1) {
        return false
      } else {
        this.timeIndex += 1
        this.fourTimeLineList.splice(0, 1)
        this.fourTimeLineList.push(this.timeLineList[this.timeIndex])
      }
    },
    moveRight() {
      if (this.timeIndex == 3) {
        return false
      } else {
        this.timeIndex -= 1
        this.fourTimeLineList.splice(this.fourTimeLineList.length - 1, 1)
        this.fourTimeLineList.splice(0, 0, this.timeLineList[this.timeIndex - 3])
      }
    },
    tlLeft() {
      if (this.tlIndex > 0) {
        this.tlIndex = this.tlIndex - 1;

        this.fourTimeLineList = [];
        this.companyHistoryList.forEach((item, index) => {
          // console.log(this.tlIndex, index >= this.tlIndex && index <= (this.tlIndex + 4));
          if (index >= this.tlIndex && index < (this.tlIndex + 4)) {
            this.fourTimeLineList.push(item);
          }
        })
      }
    },
    tlRight() {
      console.log(this.tlIndex);
      if (this.companyHistoryList.length >= this.tlIndex + 5) {
        this.tlIndex = this.tlIndex + 1;
        this.fourTimeLineList = [];
        this.companyHistoryList.forEach((item, index) => {
          // console.log(this.tlIndex, index >= this.tlIndex && index <= (this.tlIndex + 4));
          if (index >= this.tlIndex && index < (this.tlIndex + 4)) {
            this.fourTimeLineList.push(item);
          }
        })
      }

      // this.fourTimeLineList = [];
      // console.log(this.companyHistoryList);
      // this.companyHistoryList.forEach((item, index) => {
      //   console.log(this.tlIndex, index >= this.tlIndex && index < (this.tlIndex + 4));
      //   if (index >= this.tlIndex && index < (this.tlIndex + 4)) {
      //     if ((this.companyHistoryList.length - 4) > this.tlIndex) {
      //       this.fourTimeLineList.push(item);
      //     }
      //   }
      // })
      // console.log(this.fourTimeLineList);
    }
  },
  watch: {
    startupAnimation(newVal, oldVal) {
      if (newVal === false)
        document.body.style.overflow = "auto";
    }
  },
  computed: {
  },
  components: {
  }
};
</script>

<style scoped></style>
