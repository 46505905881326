<template>
  <article>
    <section class="staff">
      <div class="wrapper">

        <div class="subhead">
          <span>员工风采</span>
        </div>
        

        <div class="pure-g">
          <div class="pure-u-1 pure-u-xl-1-3" v-for="(item, index) in list" :key="index">
            <div class="p1-box">
              <div class="content" v-if="index < 6">
                <img :src="item.picture">
                <p class="title1">{{item.title}}</p>
              </div>
            </div>
          </div>
        </div>

        <el-pagination
          background
          layout="prev, pager, next"
          :page-size="count"
          :total="total"
          @current-change="handleCurrentChange"
          :current-page.sync="page"
          style="text-align: center;">
        </el-pagination>

      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: "ProfileStaff",
  data () {
    return {
      page: 1,
      count: 6,
      total: 0,
      list: [],
    }
  },
  created () {
    this.getList();
  },
  methods: {
    getList() {
      this.$http.get("/baseInfo/getStaffStyle", {
        pageNo: this.page,
        pageSize: this.count,
      }, res => {
        this.list = res.data;
        this.page = res.page.pageNo;
        this.count = res.page.pageSize;
        this.total = res.page.total;
      });
    },
    handleCurrentChange(e) {
      this.page = e;
      this.getList();
    }
  },
  components: {
  }
};
</script>

<style scoped>
@import "~@/assets/styles/profile-staff.css";
</style>
