import { format } from 'timeago.js';

function timeFormat(time) {
  return format(parseInt(time * 1000), "zh_CN");
}

function addClass(obj, className) {
  // 如果原来没有class
  if (obj.className == "") {
    obj.className = className;
  } else {
    var _index = classIndexOf(obj, className);
    // 如果原来没有这个新加的class
    if (_index == -1) {
      obj.className += " " + className;
    }
  }
}

function removeClass(obj, className) {
  // 如果以前的元素不为空
  if (obj.className != "") {
    var arrClassName = obj.className.split(" ");
    var _index = classIndexOf(obj, className);
    // 如果存在要删除的class
    if (_index != -1) {
      arrClassName.splice(_index, 1);
    }
    obj.className = arrClassName.join(" ");
  }
}

// 检验是否包含有某一个class
function classIndexOf(obj, v) {
  var arrClassName = obj.className.split(" ");
  for (var i = 0; i < arrClassName.length; i++) {
    if (arrClassName[i] == v) {
      return i;
    }
  }
  return -1;
}

export default {
  timeFormat: function(time) {
    return timeFormat(time);
  },
  addClass: function(obj, className) {
    return addClass(obj, className);
  },
  removeClass: function(obj, className) {
    return removeClass(obj, className);
  },
  classIndexOf: function(obj, className) {
    return classIndexOf(obj, className);
  }
};
