<template>
  <article>
    <section class="news">
      <div class="wrapper">
        <div class="p1-box">
          <div class="detail-title">{{ form.title }}</div>
          <div class="detail-datetime">
            <div class="info">发表时间：{{ form.publishTime }}</div>
            <div class="info" style="text-align: right;"><!-- 点击次数：{{ form.clickTotal }} --></div>
          </div>
          <div class="detail-content" v-html="form.content"></div>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: "NewsRead",
  data () {
    return {
      form: {}
    }
  },
  created () {
    // console.log(this.$route.params.id);

    this.$http.get("/baseInfo/getNews", {
      id: this.$route.params.id
    }, res => {
      console.log(res);
      this.form = res.data[0];
    });

    this.$http.post("/baseInfo/newsClickTotal", {
      id: this.$route.params.id
    }, res => {
      console.log(res);
    })

    // this.$http.get("/jsons/news.json", {}, res => {
    //   this.newLists = res;
    //   console.log(res);
    //   this.id = this.$route.params.id
    //   this.getDetail()
    // });
  },
  methods: {
    // getDetail() {
    //   for (let i in this.newLists) {
    //     if (this.newLists[i].id == this.id) {
    //       this.detail = this.newLists[i]
    //       break
    //     } else {
    //       this.detail = ''
    //     }
    //   }
    // }
  },
  components: {
  }
}
</script>

<style scoped>
@import "~@/assets/styles/news.css";
.wrapper {
  width: 940px !important;
}
@media screen and (max-width: 80em) {

  .wrapper {
    width: auto !important;
  }
}
</style>
