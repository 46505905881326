<template>
  <article>
    <section id="contact-one">
      <div class="wrapper">
        <div class="subhead">
          <span>联系我们</span>
        </div>

        <div class="pure-g">
          <div class="pure-u-1 pure-u-xl-1-1" v-for="(item, index) in list" :key="index">
            <div class="p1-box">
              <p class="lxwm-company">{{ item.companyName }}</p>
              <p class="lxwm-company-info">
                <!-- <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/contact/address.png"> -->
                <span>{{ item.companyAddress }}</span>
              </p>
              <p class="lxwm-company-info">
                <!-- <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/contact/tel.png"> -->
                <span class="phone">{{ item.companyPhone }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: "Contact",
  data () {
    return {
      list: []
    }
  },
  created () {
    this.$http.get("/baseInfo/getCompanyDetail", {
      pageNo: 1,
      pageSize: 1000,
    }, res => {
      this.list = res.data;
    });
  },
  components: {
  }
}
</script>

<style scoped>
@import "~@/assets/styles/contact.css";
</style>
