<template>
  <article>
    <section class="offer">
      <div class="offer-top">
        <div class="wrapper">
          <div class="p1-box">
            <div class="title">
              {{ list.position }}<span>{{ list.wage }}</span>
            </div>
            <p class="biaoqian">
              {{ list.treatment }}
            </p>
            <div class="shuxing-box">
              <span class="shuxing">五险一金</span>
              <span class="shuxing">双休法休</span>
              <span class="shuxing">节日福利</span>
              <span class="shuxing">提供午餐</span>
            </div>
          </div>
        </div>
      </div>

      <div class="wrapper">
        <div class="pure-g" style="margin-top: 1em;">
          <div class="pure-u-1 pure-u-xl-12-24">
            <div class="hehe">
              <p style="font-size: 20px;">职位描述</p>
              <div style="width: 28px;height: 3px;background: #08529C;margin-top: 5px;border-radius: 10px;border: 1px solid #ffffff;"></div>
              <!-- <p class="zhiweiyaoqiu-item" v-for="(zhiweiItem, zhiweiIndex) in list.zhiweimiaoshu" :key="zhiweiIndex">
                <span>{{zhiweiIndex + 1}}、</span>
                <span>{{zhiweiItem}}</span>
              </p> -->
              <!-- <p class="item" style="white-space: pre-wrap; font-size: 14px; color: #61687c;" v-html="list.jobDescription"></p> -->
              <p class="item" style="white-space: pre-wrap; font-size: 14px; color: #61687c; text-indent: -19px; line-height: 22px; padding-left: 19px;" v-for="(item, index) in list.jobDescription" :key="index">{{item}}</p>

              <div class="list-gangweiyaoqiu" style="padding-top: 30px;">
                <p style="font-size: 20px;">岗位要求</p>
                <div style="width: 28px;height: 3px;background: #08529C;margin-top: 5px;border-radius: 10px;border: 1px solid #ffffff;"></div>
                <!-- <p class="item" v-for="(gangweiItem, gangweiIndex) in list.gangweiyaoqiu" :key="gangweiIndex">
                  <span>{{gangweiIndex + 1}}、</span>
                  <span>{{gangweiItem}}</span>
                </p> -->
                <!-- <p class="item" style="white-space: pre-wrap; font-size: 14px; color: #61687c;" v-html="list.requirementsDetail"></p> -->

                <p class="item" style="white-space: pre-wrap; font-size: 14px; color: #61687c; text-indent: -19px; line-height: 22px; padding-left: 19px;" v-for="(item, index) in list.requirementsDetail" :key="index">{{item}}</p>
              </div>
            </div>
          </div>

          <div class="pure-u-1 pure-u-xl-4-24"></div>
          <div class="pure-u-1 pure-u-xl-8-24">
            <div class=" left-line" style="height: 100%;">
              <p style="font-size: 20px;">企业基本信息</p>
              <div style="width: 28px;height: 3px;background: #08529C;margin-top: 5px;border-radius: 10px;border: 1px solid #ffffff;"></div>
              <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/employment/logo.png" class="qiye-logo">
              <p class="qiye-tel">联系电话：0451-84623517</p>
              <p class="qiye-addr">工作地址：哈尔滨高新技术产业开发区科技创新城创新创业广场20号楼（秀月街178号）A307－8室</p>
            </div>
          </div>
        </div>
      </div>

    </section>
  </article>
</template>

<script>
export default {
  name: "OfferRead",
  data () {
    return {
      // list: '',
      list: {}
    }
  },
  created () {
    // console.log();
    this.$http.get("/baseInfo/getSocialRecruitment", {
      id: this.$route.params.id,
      // pageNo: 1,
      // pageSize: 1000,
    }, res => {
      // console.log(this.list);
      this.list = res.data[0];

      // this.list.jobDescription


      let code = this.list.jobDescription.split(/[(\r\n)\r\n]+/); // 根据换行或者回车进行识别
      code.forEach((item, index) => { // 删除空项
      if (!item) {
            code.splice(index, 1);
          }
       })
      code = Array.from(new Set(code)); // 去重
      this.list.jobDescription = code;

      let code1 = this.list.requirementsDetail.split(/[(\r\n)\r\n]+/); // 根据换行或者回车进行识别
      code1.forEach((item, index) => { // 删除空项
      if (!item) {
            code1.splice(index, 1);
          }
       })
      code1 = Array.from(new Set(code1)); // 去重
      this.list.requirementsDetail = code1;
      // console.log(code1);
      // this.page = res.page.pageNo;
      // this.count = res.page.pageSize;
      // this.total = res.page.total;
    }, res => {
      console.log(res);
    })
    // this.list = JSON.parse(this.$route.params.id)
  },
  components: {
  }
}
</script>

<style scoped>
@import "~@/assets/styles/offer.css";
section {
  padding-top: 0 !important;
}
</style>
