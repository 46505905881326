<template>
  <div id="app">
    <!-- 顶部 begin -->
    <header id="page-header" :class="headerStyle">
      <div class="brand">
        <div class="pure-g">
          <div class="pure-u-1-2 logo">
            <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/logo.png" />
          </div>
          <div class="pure-u-1-2 tel">
            联系方式：{{ company.companyPhone }}
          </div>
        </div>
      </div>
      <div class="menu">
        <div class="wrap">
          <router-link
            :exact="index == 0"
            :to="item.url"
            class="item"
            v-for="(item, index) in menuList"
            :key="index"
          >
            {{ item.name }}
            <div v-if="item.children" class="children">
              <!-- {{ item.children }} -->
              <router-link
                :to="val.url"
                class="children-item"
                v-for="(val, key) in item.children"
                :key="key"
              >
              {{ val.name }}
              </router-link>
            </div>
          </router-link>
        </div>
      </div>
    </header>

    <header id="header">
      <div class="navs">
        <div class="brand">
          <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/header/logo.png" />
        </div>
        <ul>
          <li><router-link
            :exact="index == 0"
            :to="item.url"
            class="item"
            v-for="(item, index) in menuList"
            :key="index"
          >
            {{ item.name }}
          </router-link></li>
        </ul>
      </div>
      <div class="topbar">
        <div class="brand">
          <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/header/logo.png" />
        </div>
        <div class="title" ref="title">
          {{ routerTitle }}
        </div>
        <div class="toggle" @click="menuToggle">
          <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/menu.png" style="width: 24px;height: 19px;" />
        </div>
      </div>
      <div class="banner" :class="headerStyle">
        
      </div>
    </header>
    <!-- 顶部 end -->

    <div id="page-wrapper" @click="page">
      <router-view></router-view>
    </div>

    <!-- 底部 begin -->
    <footer id="page-footer">
      <div class="wrapper">
        <div class="pure-g">
          <div class="pure-u-1 pure-u-xl-1-5">
            <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/index-bottom.png">
          </div>
          <div class="pure-u-1 pure-u-xl-3-5">
            <div class="contact">
              <span class="title">联系我们</span>
              <p>公司：{{ company.companyName }}</p>
              <p>电话：{{ company.companyPhone }}</p>
              <p>地址：{{ company.companyAddress }}</p>
            </div>
          </div>
          <div class="pure-u-1 pure-u-xl-1-5">
            <img src="https://swanair-website.oss-cn-beijing.aliyuncs.com/assets/images/index/qrcode.png" class="qrcode" />
          </div>
          <div class="pure-u-1">
            <div class="copyright">
              {{ company.companyName }} {{ company.copyright }} <a href="https://beian.miit.gov.cn/">{{ company.copyrightNumber }}</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
    <!-- 底部 end -->

    <back-to-top 
      transitionName="fade"
      :customStyle="backToTopStyle" 
      :visibilityHeight="300" 
      :backPosition="50">
    </back-to-top>
  </div>
</template>

<script>
import BackToTop from "@/components/BackToTop.vue";
export default {
  data() {
    return {
      backToTopStyle: {
        "right": "15px",
        "bottom": "15px",
        "width": "2.5em",
        "height": "2.5em",
        "border-radius": "5px",
        "line-height": "40px", 
        "display": "flex",
        // "background": "rgba(0, 0, 0, 0.5)"
      },
      routerTitle: this.$route.meta.title,
      headerStyle: "",
      menuList: [],
      tel: '联系方式：0451－55559999',
      company: {},
    }
  },
  methods: {
    menuToggle() {
      this.$dom.addClass(document.querySelector("body"), "is-menu-visible");
    },
    page() {
      let body = document.querySelector("body");
      this.$dom.removeClass(body, "is-menu-visible");
    },
  },
  created () {
    this.headerStyle = this.$route.name;
    // this.$http.get("/jsons/menu.json", {}, res => {
    //   this.menuList = res;
    // })
    this.$http.get("/baseInfo/getCompanyDetail", {}, res => {
      this.company = res.data[0];
    });
    this.menuList = [
        {
            "name": "首页",
            "url": "/",
            "bg": "/images/index-top-bg.jpg",
            "bgHeight": "982px"
        },
        {
            "name": "公司概况",
            "url": "/profile",
            "bg": "/images/profile-top-bg.png",
            "bgHeight": "944px",
            "children": [
              {
                "name": "公司简介",
                "url": "/profile#top-company",
                "bg": "/images/profile-top-bg.png",
                "bgHeight": "944px"
              },
              {
                "name": "员工风采",
                "url": "/profile#top-staff",
                "bg": "/images/profile-top-bg.png",
                "bgHeight": "944px"
              },
              {
                "name": "办公环境",
                "url": "/profile#top-office",
                "bg": "/images/profile-top-bg.png",
                "bgHeight": "944px"
              },
              {
                "name": "企业文化",
                "url": "/profile#top-pro",
                "bg": "/images/profile-top-bg.png",
                "bgHeight": "944px"
              },
              {
                "name": "组织架构",
                "url": "/profile#profile-one",
                "bg": "/images/profile-top-bg.png",
                "bgHeight": "944px"
              },
              {
                "name": "联系我们",
                "url": "/profile#top-contact",
                "bg": "/images/profile-top-bg.png",
                "bgHeight": "944px"
              },
            ]
        },
        {
            "name": "产品服务",
            "url": "/product",
            "bg": "/images/products-top-bg.png",
            "bgHeight": "330px"
        },
        {
            "name": "新闻中心",
            "url": "/news",
            "bg": "/images/news-top-bg.png",
            "bgHeight": "330px"
        },
        {
            "name": "社会招聘",
            "url": "/offer",
            "bg": "/images/employment-top-bg.png",
            "bgHeight": "330px"
        },
        {
            "name": "联系我们",
            "url": "/contact",
            "bg": "/images/contact-top-bg.png",
            "bgHeight": "330px"
        }
    ];
  },
  watch: {
    $route (to) {
      this.routerTitle = this.$route.meta.title;
      this.$dom.removeClass(document.querySelector("body"), "is-menu-visible");
      this.headerStyle = to.name;
    }
  },
  computed: {
  },
  components: {
    BackToTop
  }
};
</script>

<style>
@import "~@/assets/styles/main.css";
</style>
