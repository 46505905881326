import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Profile from "../views/Profile.vue";
import ProfileStaff from "../views/ProfileStaff.vue";
import Product from "../views/Product.vue";
import News from "../views/News.vue";
import NewsRead from "../views/NewsRead.vue";
import Offer from "../views/Offer.vue";
import OfferRead from "../views/OfferRead.vue";
import Contact from "../views/Contact.vue";
import History from "../views/History.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "天鹅航空集团"
    }
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: {
      title: "公司概况"
    }
  },
  {
    path: "/profile/staff",
    name: "ProfileStaff",
    component: ProfileStaff,
    meta: {
      title: "公司概况"
    }
  },
  {
    path: "/profile/history",
    name: "History",
    component: History,
    meta: {
      title: "发展历程"
    }
  },
  {
    path: "/product",
    name: "Product",
    title: "sdf",
    component: Product,
    meta: {
      title: "产品服务"
    }
  },
  {
    path: "/news",
    name: "News",
    component: News,
    meta: {
      title: "新闻中心"
    }
  },
  {
    path: "/news/:id",
    name: "NewsRead",
    component: NewsRead,
    meta: {
      title: "新闻中心"
    }
  },
  {
    path: "/offer",
    name: "Offer",
    component: Offer,
    meta: {
      title: "社会招聘"
    }
  },
  {
    path: "/offer/:id",
    name: "OfferRead",
    component: OfferRead,
    meta: {
      title: "社会招聘"
    }
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "联系我们"
    }
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  linkActiveClass: "active",
  // base: process.env.BASE_URL,
  routes,
  scrollBehavior (to) {
    if (to.hash) {
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 };
    }
  }
});

export default router;
