import axios from "axios";
// import qs from "qs";
// const baseURL = "/dev";
// const baseURL = "http://123.57.162.78:8096/test";
// const baseURL = "http://123.57.162.78:8096";
const baseURL = "https://websvr.swanair.com.cn";

function toType(obj) {
  return {}.toString
    .call(obj)
    .match(/\s([a-zA-Z]+)/)[1]
    .toLowerCase();
}

function filterNull(o) {
  for (var key in o) {
    if (o[key] === null) {
      delete o[key];
    }
    if (toType(o[key]) === "string") {
      o[key] = o[key].trim();
    } else if (toType(o[key]) === "object") {
      o[key] = filterNull(o[key]);
    } else if (toType(o[key]) === "array") {
      o[key] = filterNull(o[key]);
    }
  }
  return o;
}

function _axios(method, url, params, success, failure) {
  if (params) {
    params = filterNull(params);
  }
  axios({
    method: method,
    url: url,
    data:
      method === "POST" || method === "PUT" ? params : null,
    params: method === "GET" || method === "DELETE" ? params : null,
    baseURL: baseURL,
    withCredentials: false
  })
    .then(res => {
      // console.log("@", !res.data.msg, res);
      if (res.data.code) {
        if (success) {
          success(res.data);
        }
      } else {
        if (failure) {
          failure(res.data);
        } else {
          // console.log("DATA ERROR: " + JSON.stringify(res.data));
          return "DATA ERROR: " + JSON.stringify(res.data);
        }
      }
    })
    .catch(res => {
      if (res) {
        // console.log("HTTP ERROR: " + res.response.status);
        return "HTTP ERROR: " + res.response.status;
      }
    });
}

export default {
  baseURL,
  get: function(url, params, success, failure) {
    return _axios("GET", url, params, success, failure);
  },
  post: function(url, params, success, failure) {
    return _axios("POST", url, params, success, failure);
  },
  put: function(url, params, success, failure) {
    return _axios("PUT", url, params, success, failure);
  },
  delete: function(url, params, success, failure) {
    return _axios("DELETE", url, params, success, failure);
  }
};
